export const FIREBASE_API = {
    apiKey: 'AIzaSyDGfRwrVKlT16GYSrbuLUshbOrYFt_1R5o',
    authDomain: 'evestigatesaas.firebaseapp.com',
    projectId: 'evestigatesaas',
    storageBucket: 'evestigatesaas.appspot.com',
    messagingSenderId: '735606423891',
    appId: '1:735606423891:web:b3913ab26bcdebf8b061a63',
    measurementId: 'G-L257ZRVTC8'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config = {
    serverName: 'https://completediscovery.com',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
