const checkSlackImage = (file) => {
    const filetype = file.filetype.toLowerCase()
    
    return filetype === 'png' || filetype === 'jpg' || filetype === 'tif' || filetype === 'bmp' || filetype === 'tiff'
} 

const formatSizeUnits = (bytes) => {
    let formatted = "";
    if (bytes >= 1073741824) { formatted = `${(bytes / 1073741824).toFixed(2)} GBytes`; }
    else if (bytes >= 1048576) { formatted = `${(bytes / 1048576).toFixed(2)} MBytes`; }
    else if (bytes >= 1024) { formatted = `${(bytes / 1024).toFixed(2)} Kbytes`; }
    else if (bytes > 1) { formatted = `${bytes} bytes`; }
    else if (bytes === 1) { formatted = `${bytes} byte`; }
    else { formatted = "0 bytes"; }

    return formatted;
}

const generateEmlFromGmail = async (email) => {
    let emlData = `From: ${email.from}\r\n`;
    try {
        const boundary = '----=_Part_0_123456789.123456789'; // Boundary to separate parts
    
        // Construct the EML content
        emlData += `To: ${email.to}\r\n`;
        emlData += `Subject: ${email.subject}\r\n`;
        emlData += `Date: ${new Date(email.date).toUTCString()}\r\n`;
        emlData += `MIME-Version: 1.0\r\n`;
        emlData += `Content-Type: multipart/mixed; boundary="${boundary}"\r\n\r\n`;

        // Add plain text part (as a fallback)
        emlData += `--${boundary}\r\n`;
        emlData += `Content-Type: text/plain; charset=UTF-8\r\n\r\n`;
        emlData += `${email.preview || "This is the plain text version of the email."}\r\n\r\n`;

        // Add HTML part
        emlData += `--${boundary}\r\n`;
        emlData += `Content-Type: text/html; charset=UTF-8\r\n\r\n`;
        emlData += `${email.msg || "<p>This is the HTML version of the email.</p>"}\r\n\r\n`;

        // Add attachments (if any)
        const addAttachment = (attachment) => {
            const attachmentData = btoa(atob(attachment.data.replace(/-/g, '+').replace(/_/g, '/')));
            const contentType = attachment.mimeType;
            const fileName = attachment.filename;

            return `--${boundary}\r\n` +
                    `Content-Type: ${contentType}; name="${fileName}"\r\n` +
                    `Content-Transfer-Encoding: base64\r\n` +
                    `Content-Disposition: attachment; filename="${fileName}"\r\n\r\n` +
                    `${attachmentData}\r\n\r\n`;
        };

        if (email.attachments) {
            email.attachments.forEach(attachment => {
                if (attachment.filename && attachment.data) {
                    emlData += addAttachment(attachment);
                }
            });
        }

        // End the MIME boundary
        emlData += `--${boundary}--\r\n`;

        // Generate a hash of the EML content (before including the hash itself)
        const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(emlData));
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

        // Include the hash as a custom header
        emlData = `X-Email-Hash: ${hashHex}\r\n` + emlData;
    } catch (error) {
        console.log("Generating EML from gmail: ", error)
        emlData = null        
    }
    
    return emlData
}

const downloadGmailAsEml = async (email) => {
    const emlData = await generateEmlFromGmail(email)
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(emlData));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    // Create a title for the file using the subject and hash
    const safeSubject = email.subject.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // Sanitize the subject for use in file names
    const fileName = `${safeSubject}-${hashHex}.eml`;

    // Create a Blob from the EML data
    const blob = new Blob([emlData], { type: 'message/rfc822' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export {
    checkSlackImage,
    formatSizeUnits,
    downloadGmailAsEml,
    generateEmlFromGmail
}