import PropTypes from 'prop-types';
import { createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const MSOnedriveContext = createContext({
    channels: {
        my_files: [],
        folders: [],
    },
});

const MSOnedriveProvider = ({ children }) => {
    const navigate = useNavigate();
    const [onedriveData, setOnedriveData] = useState({
        channels: {
            my_files: [],
            folders: [],
            deltaLog: [],
            activitiesLog: [],
            sharedWithMe: []
        },
        versionHistory: [],
        fileInfo: [],
    });

    const listItemsInFolder = async (accessToken, userId, folderId = 'root') => {
        const url = `https://graph.microsoft.com/v1.0/users/${userId}/drive/items/${folderId}/children`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data.value;
        } catch (error) {
            console.error('Error listing items in folder', error);
            throw error;
        }
    }

    async function buildFileTree(accessToken, userId, folderId = 'root', path = '') {
        const items = await listItemsInFolder(accessToken, userId, folderId);
        const nodePromises = items.map(async (item) => {
            const node = {
                id: item.id,
                name: item.name,
                path: `${path}/${item.name}`,
                isFolder: item.folder !== undefined,
                downloadUrl: (item.folder !== undefined)?'':item['@microsoft.graph.downloadUrl']
        };

            if (node.isFolder) {
                node.children = await buildFileTree(accessToken, userId, item.id, node.path);
            }

            return node;
        });

        return Promise.all(nodePromises);
    }

    function flattenTree(tree) {
        const flatList = [];

        function processNode(node) {
            if (!node.isFolder) {
                flatList.push({
                    id: node.id,
                    name: node.name,
                    path: node.path,
                    isFolder: node.isFolder
                });
            }

            if (node.children && node.children.length > 0) {
                node.children.forEach(child => processNode(child));
            }
        }

        tree.forEach(node => processNode(node));
        return flatList;
    }

    const getFiles = async (accessToken, userId) => {
        try {
            if (accessToken) {
                const flatList = await buildFileTree(accessToken, userId);
                console.log('flatList JSON :', JSON.stringify(flatList, null, 2));
                console.log('flatList :',flatList);
                // const fileTree = flattenTree(flatList);
                setOnedriveData(prevData => ({
                    ...prevData,
                    channels: { ...prevData.channels, my_files: flatList }
                }));
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
                // eslint-disable-next-line no-alert
                alert(errorMessage)
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
                // eslint-disable-next-line no-alert
                alert(JSON.stringify(e))
            }
            navigate(`/ms365/apps`);
        }
    }

    const listItemHistory = async (accessToken, userId, itemId) => {
        const url = `https://graph.microsoft.com/v1.0/users/${userId}/drive/items/${itemId}/versions`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data.value;
        } catch (error) {
            console.error('Error', error);
            throw error;
        }
    }

    const metadataOfItem = async (accessToken, userId, itemId) => {
        const url = `https://graph.microsoft.com/v1.0/users/${userId}/drive/items/${itemId}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error', error);
            throw error;
        }
    }

    const getItemHistory = async (accessToken, userId, itemId) => {
        try {
            if (accessToken) {
                const historyList = await listItemHistory(accessToken, userId, itemId);
                setOnedriveData(prevData => ({
                    ...prevData,
                    versionHistory: historyList
                }));
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
            }
            // navigate(`/ms365/apps`);
        }
    }

    const getDelta = async (accessToken, userId) => {
        try {
            if (accessToken) {
                const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/drive/root/delta`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = response.data;
                setOnedriveData(prevData => ({
                    ...prevData,
                    channels: { ...prevData.channels, deltaLog: data.value }
                }));
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
            }
            // navigate(`/ms365/apps`);
        }
    }

    const getActivities = async (accessToken, userId) => {
        try {
            if (accessToken) {
                const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/drive/root/activities`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = response.data;
                setOnedriveData(prevData => ({
                    ...prevData,
                    channels: { ...prevData.channels, activitiesLog: data.value }
                }));
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
            }
            // navigate(`/ms365/apps`);
        }
    }

    const getSharedWithMe = async (accessToken, userId) => {
        try {
            if (accessToken) {
                const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/drive/sharedWithMe?allowExternal=true`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = response.data;
                setOnedriveData(prevData => ({
                    ...prevData,
                    channels: { ...prevData.channels, sharedWithMe: data.value }
                }));
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
            }
            navigate(`/ms365/apps`);
        }
    }

    const sharedFileData = async (accessToken, driveId, remoteId) => {
        const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${remoteId}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error', error);
            throw error;
        }
    }

    const sharedChildData = async (accessToken, driveId, remoteId) => {
        const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${remoteId}/children`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data.value;
        } catch (error) {
            console.error('Error', error);
            throw error;
        }
    }

    const getFileData = async (accessToken, driveId, remoteId, type) => {
        try {
            if (accessToken) {
                if (type === 'Folder') {
                    const fileData = await sharedChildData(accessToken, driveId, remoteId);
                    setOnedriveData(prevData => ({
                        ...prevData,
                        fileInfo: fileData
                    }));
                } else {
                    const fileData = await sharedFileData(accessToken, driveId, remoteId);
                    setOnedriveData(prevData => ({
                        ...prevData,
                        fileInfo: [fileData]
                    }));
                }
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.error) {
                const errorCode = e.response.data.error.code;
                const errorMessage = e.response.data.error.message;

                console.error("Error Code:", errorCode);
                console.error("Error Message:", errorMessage);
                // eslint-disable-next-line no-alert
                alert(errorMessage)
            } else {
                // General error handling if the error structure is different
                console.error("An unexpected error occurred:", e);
                // eslint-disable-next-line no-alert
                alert(JSON.stringify(e))
            }
            navigate(`/ms365/apps`);
        }
    }

    return (
        <MSOnedriveContext.Provider value={{ onedriveData, setOnedriveData, getFiles, getItemHistory, getDelta, getActivities, getSharedWithMe, getFileData, listItemsInFolder }}>
            {children}
        </MSOnedriveContext.Provider>
    );
}

MSOnedriveProvider.propTypes = {
    children: PropTypes.node
};

export { MSOnedriveContext, MSOnedriveProvider };
