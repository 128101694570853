import other from './other';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [other, admin]
};

export default menuItems;
