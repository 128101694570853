import PropTypes from 'prop-types';
import { createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosServices from "../utils/axios";

const GoogleDriveContext = createContext({
    channels: {
        my_files: [],
        folders: [],
    },
});

const GoogleDriveProvider = ({ children }) => {
    const navigate = useNavigate();
    const [driveData, setDriveData] = useState({
        channels: {
            my_files: [],
            folders: [],
            deltaLog: [],
            activitiesLog: [],
            sharedWithMe: []
        },
    });

    function buildTree(items) {
      const itemMap = {};
      const tree = [];
  
      items.forEach(item => {
          itemMap[item.id] = {...item, children: []};
      });
  
      items.forEach(item => {
          if (item.parents && item.parents.length) {
              item.parents.forEach(parentId => {
                  if (itemMap[parentId]) {
                    itemMap[parentId].children.push(itemMap[item.id]);
                  } else {
                    tree.push(itemMap[item.id]);
                  }
              });
          } else {
              tree.push(itemMap[item.id]);
          }
      });
  
      function setPath(node, basePath = '') {
          const path = `${basePath}/${node.name}`;
          node.path = path;
          node.isFolder = node.mimeType === 'application/vnd.google-apps.folder';
          node.children.forEach(child => setPath(child, path));
      }
  
      tree.forEach(root => setPath(root));
  
      return tree;
    }

    const getFiles = async (userId) => {
        try {
          if(userId){
              const result = await axiosServices.get(`/google/workspace/${userId}/v2/drive`);

              const treeDriveData = buildTree(result.data);

              setDriveData(prevData => ({
                  ...prevData,
                  channels: { ...prevData.channels, my_files: treeDriveData }
              }));
          }
        } catch {
            navigate(`/google/apps`);
        }
    }

    // const getActivities = async (accessToken, userId) => {
    //     try {
    //         if (accessToken) {
    //             const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/drive/root/activities`, {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`
    //                 }
    //             });
    //             const data = response.data;
    //             setDriveData(prevData => ({
    //                 ...prevData,
    //                 channels: { ...prevData.channels, activitiesLog: data.value }
    //             }));
    //         }
    //     } catch (e) {
    //         if (e.response && e.response.data && e.response.data.error) {
    //             const errorCode = e.response.data.error.code;
    //             const errorMessage = e.response.data.error.message;

    //             console.error("Error Code:", errorCode);
    //             console.error("Error Message:", errorMessage);
    //         } else {
    //             // General error handling if the error structure is different
    //             console.error("An unexpected error occurred:", e);
    //         }
    //         // navigate(`/google/apps`);
    //     }
    // }

    return (
        <GoogleDriveContext.Provider value={{ driveData, setDriveData, getFiles }}>
            {children}
        </GoogleDriveContext.Provider>
    );
}

GoogleDriveProvider.propTypes = {
    children: PropTypes.node
};

export { GoogleDriveContext, GoogleDriveProvider };
